import React from 'react';
import ReactDOM from 'react-dom';
import Tang from './Tang.JPG'
import Nadia from './Nadia.jpg'
import './background.css'
import Background from './marble.jpg'


// import {Departments, Majors, Courses} from './coursedb.js'

import { BrowserRouter as Router, useHistory, Link, HashRouter } from 'react-router-dom';

//for activity2
class HomePage extends React.Component {

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
   alert('A name was submitted: ' + this.state.value);
   event.preventDefault();
 }

  render() {

    return (
      <div className="background">

        <div class="column side">
        <img src={Nadia} className="border" style={{width:'50%',  position:'center'}}/>
        <p className='text'>Nadia Mohiuddin </p>
        </div>

        <div class= "column middle">
        <h1 className='header'>About Me</h1>
        <p className='text'> Hi I'm Nadia! I am a Senior studying Computer Engineering at The University of Illinois Urbana-Champaign. I am an
        incoming Software Development Engineer at Amazon. My favorite lanuages to program in are Python, Java, and React.js. In my free time I love to read and watch movies.
        </p>
        </div>

       </div>


    );
  }
}

export default HomePage
