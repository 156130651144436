import React from 'react';
import ReactDOM from 'react-dom';
import Tang from './Tang.JPG'
import './background.css'
import Background from './marble.jpg'
import { FaLinkedin, FaGithub, FaEnvelope } from 'react-icons/fa';



import { BrowserRouter as Router, useHistory, Link, HashRouter } from 'react-router-dom';

//for activity2
class Links extends React.Component {


  render() {

    return (

      <div className="background">



        <h1 className='header'>My Links</h1>

        <div class="button">
        <a href="https://www.linkedin.com/in/nmohiu11/"> <FaLinkedin size={70} color={'blue'} /></a>
        <a href="https://github.com/nadiam2"> <FaGithub size={70} color={'black'}/></a>
        <a href="mailto:nadiam2@illinois.edu"><FaEnvelope size={70} color={'black'}/></a>
        </div>



       </div>

    );
  }
}

// <Link to="https://www.linkedin.com/in/nmohiu11/"><a href="#"> <FaLinkedin size={70} color={'blue'} /></a> </Link>
// <Link to="https://github.com/nadiam2"> <a href="#"><FaGithub size={70} color={'black'}/></a> </Link>
// <Link to="https://github.com/nadiam2"> <a href="#"><FaEnvelope size={70} color={'black'}/></a> </Link>


export default Links
