import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Link, HashRouter } from 'react-router-dom';
import Route from 'react-router-dom/Route';
import HomePage from './HomePage.js'
import ForFun from './ForFun.js'
import NavBar from './NavBar.js'
import Links from './Links.js'


function App() {
  return (
    <HashRouter basename='/'>
    <div className="App">
    <NavBar/>
      <Route path="/" exact component={HomePage}/>
      <Route path="/ForFun" exact component={ForFun}/>
      <Route path="/HomePage" exact component={HomePage}/>
      <Route path="/Links" exact component={Links}/>
    </div>
    </HashRouter>
  );
}

export default App;
