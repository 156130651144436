import React from 'react';
import ReactDOM from 'react-dom';
import Tang from './Tang.JPG'
import './background.css'
import Background from './marble.jpg'


// import {Departments, Majors, Courses} from './coursedb.js'

import { BrowserRouter as Router, useHistory, Link, HashRouter } from 'react-router-dom';

//for activity2
class NavBar extends React.Component {


  render() {

    return (

      <div class="topnav">
      <Link to="/HomePage"><a href="#">About</a></Link>
      <Link to="/ForFun"><a href="#">For Fun</a></Link>
      <Link to="/Links"><a href="#">Links</a></Link>
      </div>

    );
  }
}

export default NavBar
