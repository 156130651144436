import React from 'react';
import ReactDOM from 'react-dom';
import Tang from './Tang.JPG'
import './background.css'
import Background from './marble.jpg'


// import {Departments, Majors, Courses} from './coursedb.js'

import { BrowserRouter as Router, useHistory, Link, HashRouter } from 'react-router-dom';

//for activity2
class ForFun extends React.Component {

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
   alert('A name was submitted: ' + this.state.value);
   event.preventDefault();
 }

  render() {

    return (
      <div className="background">



        <h1 className='header'>My Pet</h1>
        <img src={Tang} className="border" style={{width:'30%',  position:'center'}}/>


        <p className='text'>This is my 5 month old orange tabby kitten named Tangerine! He is super playful, loves to snuggle, and needs lots of attention!</p>


       </div>






    );
  }
}

export default ForFun
